import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import moment from 'moment'
import { toUpper } from 'lodash'
import { Link } from 'gatsby'

import Jumbotron from "../columns/Jumbotron";
import Columns from "../main/Columns";
import Layout from './Layout';
import Sidebar from '../columns/SideBar'

export const StandardContentTemplate = ({
  contents,
  relatedArticles,
  createDate,
  updateDate,
  description,
  tags,
  jumbotron,
  title,
  langKey,
  helmet,
  reusable,
  tagList,
  padding,
  disableCreateDate,
  disableTagsList

}) => {
  const today = moment().valueOf()
  let create = null
  let update = null
  if (createDate !== "" && createDate !== null) {
    create = moment(new Date(createDate)).format('YYYY.MM.DD')
  }
  if (updateDate !== "" && updateDate !== null) {
    update = moment(new Date(updateDate)).format('YYYY.MM.DD')
  }

  return (
    <>
      {jumbotron &&
        <Jumbotron
          data={jumbotron}
        />
      }
      <section
        className="section"
        style={{padding: padding}}
      >
        {helmet || ''}
        <div className="container content">
          <div className="columns" style={{paddingRight: "2.15rem", marginRight: "2.15rem"}}>
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-7 has-text-weight-bold is-bold-light">
                {title}
              </h1>
               {disableCreateDate ? null : 
              <p className={"is-size-15"}>
                {(
                      create !== null
                   && update !== null
                   && create !== update
                 ) &&
                  <b>
                    {`Created: ${create}`}{' | '}{`Last updated: ${update}`}
                  </b>
                }
                {(
                      create !== null
                   && update !== null
                   && create === update
                 ) &&
                  <b>
                    {`Created: ${create}`}
                  </b>
                }
                {(
                      create === null
                   && update !== null
                 ) &&
                  <b>
                    {`Created: prior to 2018`}{' | '}{`Last updated: ${update}`}
                  </b>
                }
                {(
                      create !== null
                   && update === null
                 ) &&
                  <b>
                    {`Created: ${create}`}
                  </b>
                }
                {(
                      create === null
                   && update === null
                 ) &&
                  <b>
                    {`Created: prior to 2018`}
                  </b>
                }
              </p>
              }
              <p>{description}</p>
              {contents && contents.length ? (
                <div>
                  {contents.map(content => (
                    <div>
                      {
                        (
                            (
                                 (
                                      (content.startDate !== "" && content.startDate !== null)
                                   && (content.endDate !== "" && content.endDate !== null)
                                 )
                              && (today >= moment(new Date(content.startDate)).valueOf() && today <= moment(new Date(content.endDate)).valueOf())
                            )
                          ||
                            (
                                 (
                                      (content.startDate !== "" && content.startDate !== null)
                                   && (content.endDate === "" || content.endDate === null)
                                 )
                              && (today >= moment(new Date(content.startDate)).valueOf())
                            )
                          ||
                            (
                                 (
                                      (content.startDate === "" || content.startDate === null)
                                   && (content.endDate !== "" && content.endDate !== null)
                                 )
                              && (today <= moment(new Date(content.endDate)).valueOf())
                            )
                          ||
                            (
                              (
                                   (content.startDate === "" || content.startDate === null)
                                && (content.endDate === "" || content.endDate === null)
                              )
                            )
                        ) ? (
                          <Columns
                            content={content}
                            langKey={langKey}
                            reusable={reusable}
                          />
                        ) : null
                      }
                    </div>
                  ))}
                </div>
              ) : null}
              {!disableTagsList && tags && tags.length && tags.some(tag => Boolean(tag.value)) ? (
                <div>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <>
                        {tagList.frontmatter.tagList.map && tagList.frontmatter.tagList.map(tagList => (
                          <>
                            {toUpper(tagList.tag) === toUpper(tag.value) &&
                              <li key={tagList.tag + `tag`}>
                                <Link to={`/${tagList.tag}/`}>{tagList.tag}</Link>
                              </li>
                            }
                          </>
                        ))}
                      </>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
            <Sidebar contents={contents} relatedArticles={relatedArticles} />
          </div>
        </div>
      </section>
    </>
  )
}


StandardContentTemplate.propTypes = {
  contents: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  createDate: PropTypes.string,
  updateDate: PropTypes.string,
  description: PropTypes.string,
  jumbotron: PropTypes.object,
  title: PropTypes.string,
  langKey: PropTypes.string,
  helmet: PropTypes.object,
  reusable: PropTypes.object,
  tagList: PropTypes.object,
  padding: PropTypes.string,
}

const StandardContent = ({ data, padding }) => {
  const { markdownRemark: post } = data
  const { tagList: tags } = data
  let { reusable: reuse } = data
  reuse = reuse.edges

  return (
    <Layout
      langKey={post.fields.langKey}
      slug={post.fields.pathSlug}
    >
      <StandardContentTemplate
        disableCreateDate={post.frontmatter.disableCreateDate}
        disableTagsList={post.frontmatter.disableTagsList}
        contents={post.frontmatter.contents}
        relatedArticles={post.frontmatter.relatedArticles}
        createDate={post.frontmatter.createDate}
        updateDate={post.frontmatter.updateDate}
        description={post.frontmatter.description}
        jumbotron={post.frontmatter.jumbotron}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        langKey={post.fields.langKey}
        reusable={reuse}
        tagList={tags}
        padding={padding}
      />
    </Layout>
  )
}

StandardContent.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    reusable: PropTypes.object,
    tagList: PropTypes.object,
  }),
  padding: PropTypes.string,
}

export default StandardContent